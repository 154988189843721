import {
  Button,
  Container,
  FileInput,
  Image,
  Paper,
  Text,
  Title,
} from "@mantine/core";
import { useForm } from "react-hook-form";
import { Select, TextInput } from "react-hook-form-mantine";
import { IconFileUpload } from "@tabler/icons-react";
import { useState, useEffect } from "react";
import { modals } from "@mantine/modals";
import { toBase64 } from "../../services/helper";
import CropImage from "../Events/Customize/CropImage";
import { extractDirtyData } from "../../utils/dirtyData";
import { useUpdateSpecialInvitation } from "../../api/announcements";

function EditSpecialInvite({ data, tags }) {
  const [newImage, setNewImage] = useState(null);

  const currentImage = newImage ? URL.createObjectURL(newImage) : data?.image;

  const tagsOptions = [
    { value: "", label: "All users" },
    ...(tags ?? []).map((tag) => ({
      value: String(tag.id),
      label: `${tag.title} - (${tag.usageCount} users)`,
    })),
  ];

  const { mutate, isPending } = useUpdateSpecialInvitation(data.id);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, dirtyFields },
    reset,
  } = useForm({
    defaultValues: {
      title: data.title,
      isVisible: String(data.isVisible),
      eventId: data?.eventId,
      targetedTagId: data?.targetedTagId ? String(data.targetedTagId) : "",
      image: data?.image,
    },
  });

  useEffect(() => {
    if (newImage) {
      toBase64(newImage).then((base64) => {
        setValue("image", base64, { shouldDirty: true });
      });
    } else {
      setValue("image", data?.image, { shouldDirty: true });
    }
  }, [newImage, setValue, data?.image]);

  const openImageModal = (img) => {
    modals.open({
      title: `${img.name}`,
      children: <Image src={img.src} style={{ maxWidth: 500 }} />,
    });
  };

  const openCropModal = (file) => {
    const imageSrc = URL.createObjectURL(file);

    modals.open({
      title: "Crop image",
      children: (
        <CropImage
          imageSrc={imageSrc}
          imageName={file.name}
          setNewHeaderImage={setNewImage}
          aspectRatio={9 / 16}
        />
      ),
    });

    // Clean up the URL object after usage
    return () => URL.revokeObjectURL(imageSrc);
  };

  const onSubmit = (data) => {
    const dirtyData = extractDirtyData(data, dirtyFields);

    if (dirtyData.isVisible) {
      dirtyData.isVisible = dirtyData.isVisible === "true";
    }

    if (dirtyData.targetedTagId === "") {
      dirtyData.targetedTagId = null;
    }

    mutate(dirtyData, {
      onSuccess: () => {
        reset({ ...data, ...dirtyData });
      },
    });
  };

  return (
    <Container size={500}>
      <Title order={1}>Edit Special Invite</Title>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Title"
            placeholder="Special Invite Title"
            control={control}
            name="title"
            required
          />

          <FileInput
            label="Header image"
            name="image"
            description="Image shown at the top of the mobile home page."
            placeholder="Upload file"
            clearable
            leftSection={<IconFileUpload />}
            style={{ width: "100%" }}
            mt={10}
            onChange={(value) => {
              setNewImage(null);
              if (value) openCropModal(value);
            }}
          />

          {data?.image && (
            <>
              <Text mt={10} size="sm" fw="500">
                Current Image:
              </Text>
              <Image
                src={currentImage}
                alt="Special Invite"
                h={350}
                w="auto"
                fit="cover"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  openImageModal({ src: currentImage, name: "Current Image" })
                }
              />
            </>
          )}

          <Select
            label="Targeted announcement"
            description="Select tag to target users"
            placeholder="Select targeted tag"
            mt="sm"
            name="targetedTagId"
            control={control}
            data={tagsOptions}
            allowDeselect={false}
          />

          <Select
            label="Visibility"
            placeholder="Select visibility"
            control={control}
            name="isVisible"
            required
            data={[
              { value: "true", label: "Visible" },
              { value: "false", label: "Not Visible" },
            ]}
            mt="sm"
          />
          <Button type="submit" mt={10} disabled={!isDirty} loading={isPending}>
            Edit
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default EditSpecialInvite;

import { useApiQuery, useApiMutation } from "../services/react-query";

export const useGetAllAttendees = (params) => {
  return useApiQuery({
    queryOptions: {
      queryKey: ["attendees", params],
    },
    fetchOptions: {
      url: "/attendees",
      method: "GET",
      params,
    },
  });
};

export const useCreateAttendee = () => {
  return useApiMutation({
    queryOptions: {
      queryKey: ["attendees"],
    },
    fetchOptions: {
      url: "/attendees",
      method: "POST",
    },
    successMessage: {
      title: "Attendee was created successfully",
    },
  });
};

export const useUpdateAttendee = ({ attendeeId }) => {
  return useApiMutation({
    queryOptions: {
      queryKey: ["attendees"],
    },
    fetchOptions: {
      url: `/attendees/${attendeeId}`,
      method: "PATCH",
    },
    successMessage: {
      title: "Attendee was updated successfully",
    },
  });
};

export const useGetAttendee = ({ attendeeId }) => {
  return useApiQuery({
    queryOptions: {
      queryKey: ["attendees", attendeeId],
    },
    fetchOptions: {
      url: `/attendees/${attendeeId}`,
      method: "GET",
    },
  });
};

export const useImportAttendees = () => {
  return useApiMutation({
    queryOptions: {
      queryKey: ["attendees"],
    },
    fetchOptions: {
      url: "/attendees/import",
      method: "POST",
    },
    successMessage: {
      title: "Attendees were imported successfully",
    },
  });
};

import { useContext } from "react";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";
import { useState } from "react";
import { useGetAllAnnouncements } from "../api/announcements";
import { useGetTagColors } from "../api/tag";
import AnnouncementsTable from "../components/Announcements/AnnouncementsTable";

function Announcements() {
  const { user } = useContext(AuthContext);

  const currentWorkspace = getCurrentWorkspace(user);

  const eventId = currentWorkspace.eventId;

  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const { data } = useGetAllAnnouncements({
    eventId,
    getAll: true,
    ...pagination,
  });

  const { data: tagColors } = useGetTagColors(eventId);

  return (
    <AnnouncementsTable
      data={data}
      pagination={{ data: pagination, setPagination }}
      tagColors={tagColors}
    />
  );
}

export default Announcements;

import React from "react";
import { DataTable } from "mantine-datatable";
import { Badge, Group, ActionIcon, Avatar } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import PaidCheckbox from "./PaidCheckbox";
import { CurrencyInput } from "./CurrencyInput";

function AttendeesTable({ data, pagination, sorting, isPending }) {
  return (
    <DataTable
      records={data?.data}
      columns={[
        {
          accessor: "picture",
          render: (row) => (
            <Avatar
              src={row.picture}
              size="md"
              radius="sm"
              alt="avatar"
              color="initials"
              name={`${row.firstName} ${row.lastName}`}
            />
          ),
          align: "center",
        },
        { accessor: "firstName", sortable: true },
        { accessor: "lastName", sortable: true },
        { accessor: "email", sortable: true },
        {
          accessor: "status",
          render: (row) => (
            <Badge
              color={row.status !== "bounced" ? "blue" : "red"}
              variant="light"
            >
              {row.status}
            </Badge>
          ),
        },
        {
          accessor: "category",
          render: (row) => {
            const tag = row.tags.find(
              (tag) => tag.subtype === "categoryOfUser"
            );

            if (!tag) return null;

            return (
              <Badge color={tag.color} variant="light">
                {tag.title}
              </Badge>
            );
          },
        },
        {
          accessor: "paid",
          render: (row) => (
            <PaidCheckbox attendeeId={row.id} checked={row.paid} />
          ),
          sortable: true,
        },
        {
          accessor: "amountPaid",
          width: 250,
          render: (row) => (
            <CurrencyInput
              amountValue={{
                amountPaid: row.amountPaid,
                currency: row.currency,
              }}
              attendeeId={row.id}
            />
          ),
        },
        {
          accessor: "actions",
          width: 100,
          render: (row) => (
            <Group justify="center">
              <ActionIcon
                size="md"
                variant="light"
                component={Link}
                to={`${row.id}`}
              >
                <IconEdit />
              </ActionIcon>{" "}
            </Group>
          ),
        },
      ]}
      withTableBorder
      striped
      withColumnBorders
      highlightOnHover
      page={pagination.data.page}
      onPageChange={(page) =>
        pagination.setPagination({ ...pagination.data, page })
      }
      sortStatus={sorting.data}
      onSortStatusChange={sorting.setSorting}
      fetching={isPending}
      recordsPerPage={data?.pagination.perPage}
      totalRecords={data?.pagination.total}
      minHeight={150}
    />
  );
}

export default AttendeesTable;
